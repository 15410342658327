import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import '../styles/baseline.css';
import '../styles/index.css';

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "carolnordman.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout location={location}>
      <Seo title='Home' />
      <div className='Home__root'>
        <Img
          /*To style the Img container use style={}, 
            to style the <img> itself use imgStyle={}*/
          style={{
            marginRight: '2rem',
            marginBottom: '2rem',
            width: '350px',
            flexGrow: 1,
            // maxWidth: '300px',
            // margin: '0 auto',
            // marginBottom: '1.45rem',
          }}
          fluid={data.file.childImageSharp.fluid}
        />

        <section>
          <h2>Welcome to my website!</h2>
          <p className='Home__welcomeBody'>
            You can access my book, artwork and more at the menu above.
            <br />
            <br />I hope you are blessed by what you see!
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
